<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Tables">
                    <template v-slot:description>
                        <div>
                            <div>import { HbDataTable, HbDataTableHeader } from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Basic Data Table with Data Table Header + Code" class="mt-4 mb-6">
            <div class="pb-6 mx-6">
                <hb-data-table-header class="pt-1">
                    <template v-slot:header>
                        Header Slot
                    </template>
                    <template v-slot:description>
                        This is a description for what this table is used for and how to use it.
                    </template>
                    <template v-slot:actions>
                        <hb-btn small color="secondary" @click="dialog = true">Add New Items</hb-btn>
                        <hb-modal v-model="dialog" size="medium" title="Add New Items"> <template v-slot:subheader>You can put something here or not.</template> <template v-slot:content><div class="my-6 d-flex justify-center">Amazing content goes here.</div></template> <template v-slot:actions><hb-btn color="primary" @click="dialog = false">Save</hb-btn></template> </hb-modal>
                    </template>
                </hb-data-table-header>

                <hb-data-table
                    :headers="headers"
                    :items="items"
                    @click:row="setNotification"
                    :loading="true"
                >
                    <template v-slot:item.name="{ item }">
                        <hb-icon color="#101318">mdi-account</hb-icon> {{ item.name }}
                    </template>
                    <template v-slot:item.category="{ item }">
                        {{ item.category }}
                    </template>
                    <template v-slot:item.misc="{ item }">
                        {{ item.misc }}
                    </template>
                    <template v-slot:item.other="{ item }">
                        {{ item.other }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <hb-menu options class="d-flex justify-end">
                            <v-list>

                                <v-list-item @click="setNotification('View')">
                                    <v-list-item-title>View</v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" @click="setNotification('Delete')">
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>

                            </v-list>
                        </hb-menu>
                    </template>
                </hb-data-table>

            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-data-table-header>
    &lt;template v-slot:header>
        Header Slot
    &lt;/template>
    &lt;template v-slot:description>
        This is a description for what this table is used for and how to use it.
    &lt;/template>
    &lt;template v-slot:actions>
        &lt;hb-btn small color="secondary" @click="dialog = true">Add New Items&lt;/hb-btn>
        &lt;hb-modal v-model="dialog" size="medium" title="Add New Items">
            &lt;template v-slot:subheader>You can put something here or not.&lt;/template>
            &lt;template v-slot:content>
                &lt;div class="my-6 d-flex justify-center">Amazing content goes here.&lt;/div>
            &lt;/template> &lt;template v-slot:actions>
                &lt;hb-btn color="primary" @click="dialog = false">Save&lt;/hb-btn>
            &lt;/template>
        &lt;/hb-modal>
    &lt;/template>
&lt;/hb-data-table-header>

&lt;hb-data-table
    :headers="headers"
    :items="items"
    @click:row="setNotification"
    :loading="true"
>
    &lt;template v-slot:item.name="{ item }">
        &lt;hb-icon color="#101318">mdi-account&lt;/hb-icon> <span v-pre>{{ item.name }}</span>
    &lt;/template>
    &lt;template v-slot:item.category="{ item }">
        <span v-pre>{{ item.category }}</span>
    &lt;/template>
    &lt;template v-slot:item.misc="{ item }">
        <span v-pre>{{ item.misc }}</span>
    &lt;/template>
    &lt;template v-slot:item.other="{ item }">
        <span v-pre>{{ item.other }}</span>
    &lt;/template>
    &lt;template v-slot:item.actions="{ item }">
        &lt;hb-menu options class="d-flex justify-end">
            &lt;v-list>

                &lt;v-list-item :ripple="false" @click="setNotification('View')">
                    &lt;v-list-item-title>View&lt;/v-list-item-title>
                &lt;/v-list-item>

                &lt;v-list-item :ripple="false" @click="setNotification('Delete')">
                    &lt;v-list-item-title>Delete&lt;/v-list-item-title>
                &lt;/v-list-item>

            &lt;/v-list>
        &lt;/hb-menu>
    &lt;/template>
&lt;/hb-data-table>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="<hb-data-table-header> Props" class="my-6 pb-1">
            <hb-data-table
                :headers="headerPropHeaders"
                :items="headerPropItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="<hb-data-table-header> Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="headerSlotHeaders"
                :items="headerSlotItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="<hb-data-table> Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <hb-link :href="item.resource" target="_blank" v-if="item.resource.length">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="<hb-data-table> Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="vuetifyHeaders"
                :items="slotItems"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <hb-link :href="item.resource" target="_blank">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="<hb-data-table> Events" class="my-6 pb-1">
            <hb-data-table
                :headers="vuetifyHeaders"
                :items="eventItems"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <hb-link :href="item.resource" target="_blank">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="'You have clicked on ' + notificationName + '.'"
        >
        </hb-global-notification>
    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemTables",
        data() {
            return {
                dialog: false,
                success: false,
                notificationName: '',
                headers: [
                    { text: 'Name', value: 'name' },
                    { text: 'Email', value: 'category' },
                    { text: 'Example Value', value: 'misc' },
                    { text: 'Another Value', value: 'other' },
                    { text: '', value: 'actions', align: 'right', sortable: false },
                ],
                items: [
                    { name: 'Albus', category: 'Thisisatest898@storageoutlet.net', misc: '8001', other: '1001' },
                    { name: 'Voldemort', category: 'Thisisatest899@storageoutlet.net', misc: '9001', other: '2001' },
                    { name: 'Tom', category: 'Thisisatest8901@storageoutlet.net', misc: '10001', other: '30001' },
                    { name: 'Severus', category: 'Thisisatest8902@storageoutlet.net', misc: '11001', other: '41001' },
                    { name: 'Bellatrix', category: 'Thisisatest8903@storageoutlet.net', misc: '12001', other: '52001' },
                    { name: 'Griffindor', category: 'Thisisatest8904@storageoutlet.net', misc: '13001', other: '63001' },
                    { name: 'Horcrux', category: 'Thisisatest8905@storageoutlet.net', misc: '14001', other: '74001' },
                ],
                headerPropHeaders: [
                    { text: 'Name', value: 'name', width: '130' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' }
                ],
                headerPropItems: [
                    { name: 'left-columns', type: 'string', default: '9', description: 'Changes the default left column size. Use a number between 1 and 12. Be sure to set the corresponding right column size and have the sum of the two columns be equal to 12.' },
                    { name: 'right-columns', type: 'string', default: '3', description: 'Changes the default right column size. Use a number between 1 and 12. Be sure to set the corresponding left column size and have the sum of the two columns be equal to 12.' },
                ],
                headerSlotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                headerSlotItems: [
                    { name: 'header', description: 'This slot is where you normally place the header text for the data table.' },
                    { name: 'description', description: 'This slot is where you normally place the description text for the data table.' },
                    { name: 'actions', description: 'This slot is normally where you place the action links or buttons related to the data table.' },
                ],
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                    { text: 'Resource', value: 'resource' },
                ],
                propItems: [
                    { name: 'card', type: 'boolean', default: 'true', description: 'If this is changed to false, the card styling will be removed from the data table (such as the border-radius and elevation/box-shadow). Most of the time you will not need to change this property as most of our designs call for a table with border radius and elevation/box-shadow.', resource: '' },
                    { name: 'all vuetify v-data-table props', type: '', default: '', description: 'All vuetify "v-data-table" props can be used in an "hb-data-table". See the vuetify documentation link in the next column for the list of available props.', resource: 'https://v2.vuetifyjs.com/en/api/v-data-table/' },
                ],
                vuetifyHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                    { text: 'Resource', value: 'resource' },
                ],
                slotItems: [
                    { name: 'all vuetify v-data-table slots', description: 'All vuetify "v-data-table" slots can be used in an "hb-data-table". See the vuetify documentation link in the next column for the list of available slots.', resource: 'https://v2.vuetifyjs.com/en/api/v-data-table/' },
                ],
                eventItems: [
                    { name: 'all vuetify v-data-table events', description: 'All vuetify "v-data-table" events can be used in an "hb-data-table". See the vuetify documentation link in the next column for the list of available events.', resource: 'https://v2.vuetifyjs.com/en/api/v-data-table/' },
                ],
            }
        },
        methods: {
            setNotification(item){
                if(item === 'View'){
                    this.notificationName = 'View';
                } else if(item === 'Delete'){
                    this.notificationName = 'Delete';
                } else {
                    this.notificationName = item.name;
                }
                this.success = true;
            }
        }
    }
</script>

<style scoped>

</style>